import React from "react";
import { RecoilRoot } from "recoil";

import "./src/styles/reset.scss";
import "./src/styles/variables.scss";
import "./src/styles/fonts.scss";
import "./src/styles/global.scss";

export const wrapPageElement = ({ element, props }) => (
  <RecoilRoot {...props}>{element}</RecoilRoot>
);
